<template>
  <div class="calendar-week-selector">
    <b-input-group class="mb-3">
      <b-form-input @click="toggleDatePicker" v-model="currentDate" :readonly="true"></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          ref="datePicker"
          v-model="calendarWeek"
          button-only
          button-variant="primary"
          :min="minDate"
          :hide-header="true"
          :show-decade-nav="false"
          :locale="locale"
          :startWeekday="startWeekday"
          :dateFormatOptions="dateFormatOptions"
          @input="datePickerChange"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CalendarWeekSelector',
  props: {
    minWeekDate: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      locale: this.$store.getters['gui/getLanguage'],
      startWeekday: this.$store.getters['gui/getDateLanguage'].options.weekStartsOn,
      dateFormatOptions: { year: 'numeric', month: 'long', day: 'numeric' },
      calendarWeek: null,
      minDate: null,
      currentDate: null
    }
  },
  methods: {
    datePickerChange (date) {
      if (date) {
        const startDate = moment(date).startOf('isoWeek')
        const endDate = moment(date).endOf('isoWeek')

        this.$emit('calendar-week-select', startDate.toDate(), endDate.toDate())
        this.calendarWeek = startDate.toDate()
        const startDateString = startDate.format('DD.MM.YYYY')
        const endDateString = endDate.format('DD.MM.YYYY')
        this.currentDate = `${startDateString} - ${endDateString}`
      }
    },
    toggleDatePicker () {
      this.$refs.datePicker.$refs.control.$refs.toggle.dispatchEvent(new Event('click'))
    }
  },
  created () {
    if (this.minWeekDate) {
      this.minDate = new Date(this.minWeekDate.getFullYear(), this.minWeekDate.getMonth(), this.minWeekDate.getDate() - this.minWeekDate.getDay() + 8)
    }
  }
}
</script>

<style lang="scss">
.calendar-week-selector{
  .dropdown .dropdown-toggle.btn{
    background-image:none;
  }

  .b-calendar-grid-body{
    .row{
       &:hover{
        div{
          span{
            background-color: $light;
            border-radius:0 !important; //Because bootstrap has important to make it importanter
          }
        }
      }

      div[aria-selected="true"],
      div[aria-selected="true"] ~ div{
        span{
          background-color: $primary;
          color: $dark;
          border-radius:0 !important; //Because bootstrap has important to make it importanter
        }
      }
      div{
        span{
          border-radius:0 !important; //Because bootstrap has important to make it importanter
          box-shadow: none;

          &.focus{
            border:none;
          }
        }
      }
    }
  }
}
</style>
