<template>
  <c-article-data class="product-faqs" wrapper>
    <template #title>
      <slot name="title"/>
    </template>

    <c-accordion>
      <slot/>
    </c-accordion>
  </c-article-data>
</template>

<script>
export default {
  name: 'ProductFaqs'
}
</script>

<style lang="scss">
.product-faqs {}
</style>
