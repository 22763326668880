<template>
  <div :class="['product-price', { 'inherit': inherit, 'is-sum': quantity > 1 }]">
    <b-row no-gutters :align-h="alignH">
      <b-col cols="auto">
        <i18n-n
          :value="activePrice.number * quantity"
          :format="{ key: 'currency', currency }"
          :class="['price-active', { 'is-promotional': priceinfo.isPromotionalPrice, 'hide-fraction': activePrice.hideFraction }]"
        >
          <template #currency="{ currency }"><span class="currency">{{ currency }}</span></template>
          <template #integer="{ integer }"><span class="integer">{{ integer }}</span></template>
          <template #group="{ group }"><span class="group">{{ group }}</span></template>
          <template #decimal="{ decimal }"><span class="decimal">{{ decimal }}</span></template>
          <template #fraction="{ fraction }"><span class="fraction">{{ fraction }}</span></template>
        </i18n-n>
      </b-col>

      <b-col cols="auto">
        <i18n-n
          v-if="priceOriginal.isValid"
          :value="priceOriginal.number * quantity"
          :format="{ key: 'currency', currency }"
          :class="['price-original', { 'hide-fraction': priceOriginal.hideFraction }]"
        >
          <template #currency="{ currency }"><span class="currency">{{ currency }}</span></template>
          <template #integer="{ integer }"><span class="integer">{{ integer }}</span></template>
          <template #group="{ group }"><span class="group">{{ group }}</span></template>
          <template #decimal="{ decimal }"><span class="decimal">{{ decimal }}</span></template>
          <template #fraction="{ fraction }"><span class="fraction">{{ fraction }}</span></template>
        </i18n-n>
      </b-col>
    </b-row>

    <div class="price-vatinfo" v-if="$te(`${tPath}.vatinfo`) && !hideVatInfo">{{ $t(`${tPath}.vatinfo`) }}</div>
  </div>
</template>

<script>
import { PRODUCT_ASSEMBLYTYPES, PRODUCT_ASSEMBLYTYPE_DEFAULT } from '@/constants'

const ASSEMBLYTYPES = Object.values(PRODUCT_ASSEMBLYTYPES)

export default {
  name: 'ProductPrice',
  props: {
    productId: {
      type: String,
      required: true
    },
    priceinfo: {
      type: Object,
      default: () => {
        return {
          currency: 'CHF',
          price: 0,
          priceUnassembled: 0,
          priceOriginal: 0
        }
      }
    },
    quantity: {
      type: Number,
      default: 1
    },
    alignH: {
      type: String,
      default: 'end',
      validator: value => ['start', 'center', 'around', 'between', 'end'].includes(value)
    },
    hideVatInfo: {
      type: Boolean,
      default: false
    },
    inherit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currency: this.priceinfo.currency || 'CHF',
      price: this.createPrice(this.priceinfo.price),
      priceUnassembled: this.createPrice(this.priceinfo.priceUnassembled),
      priceOriginal: this.createPrice(this.priceinfo.priceOriginal)
    }
  },
  computed: {
    assemblyType () {
      return (this.$store.getters['shoppingcart/getLocalConfiguration'](this.productId) || {}).assemblyType || PRODUCT_ASSEMBLYTYPE_DEFAULT
    },
    activePrice () {
      return this[(ASSEMBLYTYPES.find(t => t.type === this.assemblyType) || {}).associatedPrice || 'price']
    }
  },
  methods: {
    createPrice (value) {
      const number = Number(value)

      return {
        isValid: !isNaN(number) && number > 0,
        original: value,
        number: number,
        hideFraction: number % 1 === 0
      }
    }
  }
}
</script>

<style lang="scss">
$productprice-active-font-size: 1.4em !default;
$productprice-active-font-weight: $font-weight-normal !default;
$productprice-active-color: $gray-700 !default;

$productprice-active-currency-font-size: 0.7em !default;
$productprice-active-fraction-font-size: inherit !default;

$productprice-active-promotional-color: $red !default;

$productprice-original-gap: $spacer * 0.5 !default;
$productprice-original-font-size: $productprice-active-font-size !default;
$productprice-original-font-weight: $productprice-active-font-weight !default;
$productprice-original-color: $productprice-active-color !default;
$productprice-original-linethrouth: set-nth(map-get($borders, 'base'), 3, rgba($productprice-original-color, 0.85)) !default;
$productprice-original-linethrouth-glow: 0 1px 2px 0 rgba($productprice-original-color, 0.85) !default;

$productprice-original-currency-font-size: $productprice-active-currency-font-size !default;
$productprice-original-fraction-font-size: $productprice-active-fraction-font-size !default;

$productprice-vatinfo-font-size: $font-size-sm !default;
$productprice-vatinfo-color: $gray-600 !default;

$productprice-sum-font-weight: $font-weight-bold !default;

.product-price {
  .price-active {
    display: block;
    font-size: $productprice-active-font-size;
    font-weight: $productprice-active-font-weight;
    color: $productprice-active-color;

    .currency { font-size: $productprice-active-currency-font-size; }
    .integer {}
    .group {}
    .decimal {}
    .fraction { font-size: $productprice-active-fraction-font-size; }

    &.is-promotional {
      color: $productprice-active-promotional-color;
    }
  }

  .price-original {
    display: block;
    position: relative;
    margin-left: $productprice-original-gap;
    font-size: $productprice-original-font-size;
    font-weight: $productprice-original-font-weight;
    color: $productprice-original-color;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 52.5%;
      left: 0;
      right: 0;
      border-bottom: $productprice-original-linethrouth;
      box-shadow: $productprice-original-linethrouth-glow;
    }

    .currency { font-size: $productprice-original-currency-font-size; }
    .integer {}
    .group {}
    .decimal {}
    .fraction { font-size: $productprice-original-fraction-font-size; }
  }

  .hide-fraction {
    .decimal,
    .fraction {
      display: none;
    }
  }

  .price-vatinfo {
    font-size: $productprice-vatinfo-font-size;
    color: $productprice-vatinfo-color;
    text-align: right;
  }

  &.inherit {
    .price-active,
    .price-original {
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    .price-active {
      &.is-promotional {
        color: $productprice-active-promotional-color;
      }
    }
  }

  &.is-sum {
    .price-active,
    .price-original {
      font-weight: $productprice-sum-font-weight;
    }
  }
}
</style>
